import React, { Fragment } from "react";
import PropTypes from "prop-types";
import LinkItem from "./secondary-link-item";

const SecondaryLinkList = ({ data }) => {
  return (
    <>
      <div className="secondary-link-list">
        <ul className="content" data-cy="Footer secondary links">
          {data &&
            data.map((item, i) => {
              return (
                <Fragment key={`${item?.link}-${item?.text}`}>
                  <li>
                    <LinkItem
                      link={item?.link}
                      text={item?.text}
                      target={item?.target}
                      actionId={item?.actionId}
                    />
                  </li>
                </Fragment>
              );
            })}
        </ul>
        <style jsx>{`.secondary-link-list {
  margin: 0 auto;
}
.secondary-link-list :global(li) {
  display: flex;
  flex-direction: column;
  button {
    min-height: 3rem;
  }
}
.secondary-link-list :global(li) :global(button) {
  min-height: 3rem;
  flex-flow: column;
}
.secondary-link-list :global(li) :global(a) {
  text-decoration-color:white;
  min-height: 3rem;
}

.secondary-link-list
  .content
  :global(li:not(:first-child))
  :global(.text::before) {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: white;
  content: "|";
}
.secondary-link-list .content {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding-top: 1.25rem;
  padding-left: 0px;
  padding-right: 0px;
  row-gap: 0.5rem;
}

@media screen and (min-width: 768px) {
  .secondary-link-list .content :global(li) {
    min-height: auto;
  }
  .secondary-link-list :global(li) :global(button) {
    min-height: auto;
    flex-flow: column;
  }
  .secondary-link-list :global(li) :global(a) {
    min-height: auto;
  }
}
@media screen and (min-width: 1024px) {
  .secondary-link-list .content {
    padding: 1.5rem 0;
  }
}
`}</style>
      </div>
    </>
  );
};

export default SecondaryLinkList;

SecondaryLinkList.propTypes = {
  /**
   * {
   *  "info": "Lista de objetos con los campos necesarios para contribuir el listado de links",
   *  "kind": "both",
   *  "beautifulName": "Lista de links"
   * }
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string,
      target: PropTypes.oneOf(["_blank", "_self", "_parent", "_top"]),
    })
  ),
};
