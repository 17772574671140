import React, { Component } from "react";
import dynamic from "next/dynamic";
import CustomLink from "@/bit/components/components.custom-link";
import Text from "@/bit/components/components.text";
import IconGlyph from '@/bit/components/components.icon-glyph'
import { connect } from "react-redux";
import { actions as modalActions } from "@/bit/reducers/reducers.modals-reducer";
const ModalUser = dynamic(() => import("../../../modals/user"));

const getIconSet = (iconId, location) => {
  if (!iconId) return null;

  const iconName = iconId.toLowerCase();
  const isBurgerLocation = location === "burger";

  if (iconName === "tiendas" || iconName === "ayuda" || iconName === "usuario") {
    return (
      <>
        {isBurgerLocation && (
          <>
            <IconGlyph
              className={`${iconName}-m no-hover`}
              color="blue"
              id={`${iconName}-m`}             
              width={24}
              height={24}             
            />
            <IconGlyph
              className={`${iconName}-m hover`}
              color="white"
              id={`${iconName}-m`}
              width={24}
              height={24}
            />
          </>
        )}
        {!isBurgerLocation && (
          <IconGlyph
            className={`${iconName}-m`}
            color="grey6"
            id={`${iconName}-m`}            
            width = {iconName === 'usuario' ? 22 : 24}
            height = {iconName === 'usuario' ? 22 : 24}
          />
        )}
      </>
    );    
  } else {
    return null;
  }
};

class IconsMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpened: false,
    };
  }
  openModal = () => {
    const { addModal, userName, account, privateArea } = this.props;
    addModal({
      id: "account",
      Element2Show: ModalUser,
      modalProps: {
        contentPosition: "right--top",
        opacityLayoutColor: "blur",
        inSceneEffect: "fadeInTop",
        contentInsideContainer: true,
        outSceneEffect: "fadeOutTop",
        closeAllOnClickOuside: true,
        closeByClickingOutside: true,
        onCloseHandler: this.onCloseHandler,
      },
      elementProps: {
        items: { ...account },
        userName: userName,
        onCloseHandler: this.onCloseHandler,
        privateArea: privateArea
      },
    });
    this.setState({
      modalOpened: true,
    });
  };

  onCloseHandler = () => {
    this.setState({
      modalOpened: false,
    });
  };

  render() {
    if (!this.props["items"]) {
      return null;
    }
    const items = Object.values(this.props["items"]);
    const { location } = this.props;
    let i = 0;
    return items.map((item) => {
      let link = item.link;

      const iconId = item["icon-id"];
      const text = item.text;
      const ariaLabel= item.ariaLabel? item.ariaLabel: item.text;
      const title= item.title? item.ariaLabel: item.text;

      const cyData = this.props["isModalBurger"]
        ? `Header Burger item ${text}`
        : `Header item ${text}`;
      let _onClick = null;
      if (item["action-id"]) {
        _onClick = this.openModal;
        link = "";
      }
      if (item?.["text"] === "Identifícate"){
        const prefixlink = "https://www.movistar.es/auth/login?redirect=";
        _onClick = () => {
          const currentLocation = `${encodeURIComponent(window.location.href)}`;
          window.location.href = `${prefixlink}${currentLocation}`;
        }
        link = "";
      }
      return (
        <CustomLink
          key={`item-${i++}`}
          href={link}
          onClick={_onClick}
          title={title}
          ariaLabel={ariaLabel}
          taggingInfo={{
            componentName: "header",
            componentContent: text,
            componentVariation: location,
            componentPerso: "",
            componentProduct: "",
          }}
          cyData={cyData}
        >
          <div className={`menu-item ${iconId}`}>
            <div className="icon-gap">
              {getIconSet(iconId, location)}
            </div>
            <div className="text-gap">
              <Text
                text={text}
                align="center"
                fontType="font-regular"
                size="caption"
                color="grey6"
                display="block"
              />
            </div>
          </div>
        </CustomLink>
      );
    });
  }
}

const mapStateToProps = (state) => ({
  modals: state.modals.modalHistory,
});

const mapDispatchToProps = (dispatch) => ({
  addModal: (data) => dispatch(modalActions.addModal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IconsMenu);
