import React from "react";

export const SPIN_TYPE = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  DANGER: "danger",
  CUSTO: "custo",
  CUSTO_REVERSE: "custo_reverse",
};

export const LOADING_STATE = {
  LOADING: "loading",
  SUCCESS: "success",
};
/**
 * Calcula el color del spin
 * @returns primary OR secondary or danger
 */
const setColorOfSpin = (spinType) => {
  let stroke = "#019df4";
  let fill = null;

  switch (spinType) {
    case SPIN_TYPE.PRIMARY:
    case SPIN_TYPE.CUSTO:
    case SPIN_TYPE.DANGER:
      stroke = "#fff";
      fill = "#fff";
      break;

    case SPIN_TYPE.SECONDARY:
      stroke = "#019df4";
      break;

    case SPIN_TYPE.CUSTO_REVERSE:
      stroke = "#209e91";
      break;

    default:
      break;
  }

  return { stroke, fill };
};

const generateSizes = (size) => {
  switch (size) {
    case "small":
      return {
        height: 18,
        width: 18,
        viewBox: "0 0 36 36",
      };

    case "extra-big":
      return {
        height: 100,
        width: 100,
        viewBox: "0 0 36 36",
      };

    default:
      return {
        height: 24,
        width: 24,
        viewBox: "0 0 36 36",
      };
  }
};

const Spin = ({ size, operation, spinType, strokeColor, fillColor }) => {
  const data = generateSizes(size);
  const colors = setColorOfSpin(spinType);

  const ButtonSpinner = {
    success: (
      <svg
        height={data.height}
        width={data.width}
        className={`Spin ${operation}`}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 130.2 130.2"
        style={{
          fill: fillColor || colors.fill,
          stroke: strokeColor || colors.stroke,
        }}
      >
        <circle
          className="path circle"
          fill="none"
          strokeWidth="6"
          strokeMiterlimit="10"
          cx="65.1"
          cy="65.1"
          r="62.1"
        />
        <polyline
          className="path check"
          fill="none"
          strokeWidth="10"
          strokeLinecap="round"
          strokeMiterlimit="10"
          points="100.2,40.2 51.5,88.8 29.8,67.5 "
        />
        <style jsx>{`

 `}</style>
      </svg>
    ),
    failure: (
      <svg
        height={data.height}
        width={data.width}
        className={`Spin ${operation}`}
        style={{
          fill: fillColor || colors.fill,
          stroke: strokeColor || colors.stroke,
        }}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 130.2 130.2"
      >
        <circle
          className="path circle"
          fill="none"
          strokeWidth="6"
          strokeMiterlimit="10"
          cx="65.1"
          cy="65.1"
          r="62.1"
        />
        <line
          className="path line"
          fill="none"
          strokeWidth="10"
          strokeLinecap="round"
          strokeMiterlimit="10"
          x1="34.4"
          y1="37.9"
          x2="95.8"
          y2="92.3"
        />
        <line
          className="path line"
          fill="none"
          strokeWidth="10"
          strokeLinecap="round"
          strokeMiterlimit="10"
          x1="95.8"
          y1="38"
          x2="34.4"
          y2="92.2"
        />
        {/* INJECT_STYLED_JSX_TAG */}
      </svg>
    ),
    loading: (
      <svg
        version="1.1"
        id="loader-1"
        height={data.height}
        width={data.width}
        className={`Spin ${operation}`}
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 50 50"
        style={{
          enableBackground: "new 0 0 50 50",
          fill: fillColor || colors.fill,
          stroke: strokeColor || colors.stroke,
        }}
        xmlSpace="preserve"
      >
        <path
          className="path circle"
          d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
        />
        {/* INJECT_STYLED_JSX_TAG */}
      </svg>
    ),
  };

  return ButtonSpinner[operation];
};

export default Spin;
