import dynamic from "next/dynamic";
import React from "react";
import Sticky from "./sticky";
import Header1Main from "./containers/header1-main";
import Header2Nav from "./containers/header2-nav";
import Header3Breadcrumb from "./containers/header3-breadcrumb";
import Header4Section from "./containers/header4-section";
import Header5SecondaryMenu from "./containers/header5-secondary-menu";
import Header6Title from "./containers/header6-title";
import { connect } from "react-redux";

const Header = (props) => {
  const {
    logged,
    userName,
    userType,
    contextLoaded,
    mainOptions,
    navSections,
    segmentMenu,
    burgerSections,
    burgerOptions,
    account,
    pills,
    pillsLogged,
    breadcrumbs,
    secondaryMenu,
    //Fuerza la seleccion de un item del header (por text), omitiendo el matcheo automatico entre url y breadcrumb
    forceSelect,
    showCP = true,
    extraBreadcrumbsProps = null,
    loadingHeaderLocation = false,
    logoutBackToHome = false,
    privateArea = false,
    hideNavMenu = false,
  } = props;
  if (process.env.NEXT_PUBLIC_WEB_VIEW === "true") {
    return null;
  }

  return (
    <>
      <header className="header">
        <Sticky behaviour="ALL_STICKY">
          <Header1Main
            mainOptions={mainOptions}
            navSections={navSections}
            segmentMenu={segmentMenu}
            burgerSections={burgerSections}
            burgerOptions={burgerOptions}
            accountSections={account}
            logged={logged}
            userName={userName}
            userType={userType}
            breadcrumb={breadcrumbs}
            contextLoaded={!!contextLoaded}
            logoutBackToHome={logoutBackToHome}
            privateArea={privateArea}
            forceSelect={forceSelect}
          />
        </Sticky>
        {!hideNavMenu && (
          <Header2Nav
            navSections={navSections}
            segmentMenu={segmentMenu}
            breadcrumb={breadcrumbs}
            logged={logged}
            showCP={showCP}
            forceSelect={forceSelect}
          />
        )}
        <Header3Breadcrumb
          breadcrumb={breadcrumbs}
          extraBreadcrumbsProps={extraBreadcrumbsProps}
          loadingHeaderLocation={loadingHeaderLocation}
          forceSelect={forceSelect}
        />
        <Header4Section
          pills={pills}
          pillsLogged={pillsLogged}
          logged={logged}
        />
        {secondaryMenu && secondaryMenu.items && (
          <Header5SecondaryMenu secondaryMenu={secondaryMenu} />
        )}
        <Header6Title
          loadingHeaderLocation={loadingHeaderLocation}
          theme={
            breadcrumbs && breadcrumbs["title-theme"]
              ? breadcrumbs["title-theme"]
              : null
          }
          title={
            breadcrumbs && breadcrumbs["title-text"]
              ? breadcrumbs["title-text"]
              : breadcrumbs && breadcrumbs["title"]
              ? breadcrumbs["title"]
              : null
          }
          textProps={
            breadcrumbs && breadcrumbs["textProps"]
              ? breadcrumbs["textProps"]
              : {}
          }
        />
        <style jsx>{`.header {
  position: relative;
  width: 100%;
  background-color: #fff;
  margin: 0 auto;
  z-index: 27;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .header {
    padding: 0px;
  }
}
`}</style>
      </header>
    </>
  );
};

const mapStateToProps = (state) => ({
  navSections: state.header.navSections,
  segmentMenu: state.header.segmentMenu,
  burgerSections: state.header.burgerSections,
  burgerOptions: state.header.burgerOptions,
  secondaryMenu: state.header.secondaryMenu,
  hideNavMenu: state.header.hideNavMenu,
  account: state.header.account,
  forceSelect: state.header.forceSelect,
  logged: state.main.logged,
  contextLoaded: state.main.context.contextSeoMeta,
  userName: state.main.context.userName,
  userType: state.main.context.userType,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
