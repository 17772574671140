import React from "react";
import CustomLink from "@/bit/components/components.custom-link";
import Text, { TEXT_COLOR, TEXT_WRAP } from "@/bit/components/components.text";

const renderPills = ({ items2render, maybeLogged }) => {
  return (
    <>
      <div className="header4-section">
        <div className="container">
          <ul className="pill-section" data-cy="Header pills">
            {items2render.map((item, index) => {
              const link = item.link ? item.link : "#";
              const text = item.text;
              const active = item.selected;
              let h1OnSelectedPill = {};
              let textProps = {
                size: "caption01",
                fontType: "font-regular",
                color: TEXT_COLOR.GREY5,
                wrap: TEXT_WRAP.NOWRAP,
              };

              if (active) {
                textProps = {
                  ...textProps,
                  color: TEXT_COLOR.BLUE_DARK,
                };
              }
              if (item && item.h1OnSelectedPill) {
                ({ h1OnSelectedPill } = item);
              }
              const taggingInfo = {
                componentName: "header",
                componentContent: text,
                componentVariation: "tab",
                componentPerso: "",
                componentProduct: "",
              };

              return (
                <li>
                  <CustomLink
                    key={`item-${text}-${index}`}
                    className={`pill-item ${active ? "tab_active" : "tab"}`}
                    href={link}
                    taggingInfo={taggingInfo}
                  >
                    <Text text={text} {...textProps} {...h1OnSelectedPill} />
                    {maybeLogged && <div className="pill-bread-skeleton" />}
                  </CustomLink>
                </li>
              );
            })}
          </ul>
        </div>
        <style jsx>{`.header4-section {
  display: flex;
  gap: 0.5rem;

  padding-top: 0.25rem;
  padding-left: 0px;
  padding-right: 0px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #dddddd;
  .container {
    padding: 0px;
    padding-left: 0.5rem;
  }
  .pill-section {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    &::-webkit-scrollbar {
      display: none;
    }

    :global(.pill-item) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 2.5rem;
      padding: 0px 1rem;
      border-radius: 0;
      border: none;
      :global(.text) {
        // font-size: 1rem !important;
      }
    }

    :global(.pill-item.tab_active) {
      background-color: transparent;
      color: #313235;
      border-bottom: 2px solid;
      border-color: #019df4;
    }

    :global(&.tab_active:focus),
    :global(&.tab_active:active) {
      outline: none;
      text-decoration: none;
    }
  }
}

@media screen and (min-width: 768px) {
  .header4-section {
    .pill-section {
      :global(.pill-item) {
        height: 3rem;
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .header4-section {
    .pill-section {
      :global(.pill-item) {
        height: 3rem;
      }
    }
  }
}
`}</style>
      </div>
    </>
  );
};

const Header4Section = ({ pills, pillsLogged, logged, h1OnSelectedPill }) => {
  if (!pills) {
    return null;
  }
  let maybeLogged = false;
  let items2render = pills;
  if (pillsLogged && logged === 0) {
    maybeLogged = true;
  } else if (logged !== 0) {
    maybeLogged = false;
  }

  if (pillsLogged && logged) {
    items2render = pillsLogged;
  }
  return renderPills({ items2render, maybeLogged, h1OnSelectedPill });
};
export default Header4Section;
