import React from "react";
import CustomLink from "@/bit/components/components.custom-link";
import IconGlyph from "@/bit/components/components.icon-glyph";
import { TEXT_COLOR } from "@/bit/components/components.text";

export const Logo = (props) => {
  return (
    <>
      <CustomLink
        href="https://www.movistar.es"
        title="Movistar"
        ariaLabel="Ir a la home"
        taggingInfo={{
          componentName: "header",
          componentContent: "LogoM",
          componentVariation: "all",
          componentPerso: "",
          componentProduct: "",
        }}
        cyData="Header Movistar logo"
      >
        <IconGlyph
          className="visible-sm movistar-m"
          id="Movistar-m"
          width={38}
          color={TEXT_COLOR.BLUE_MOVISTAR}
          priority
        />
        <IconGlyph
          className="hidden-sm medium movistar-m"
          id="Movistar-letra-m"
          height={26.73}
          width={112}
          color={TEXT_COLOR.BLUE_MOVISTAR}
          priority
        />
        <IconGlyph
          className="hidden-sm big movistar-m"
          id="Movistar-letra-m"
          height={32}
          width={134}
          color={TEXT_COLOR.BLUE_MOVISTAR}
          priority
        />
      </CustomLink>
      <style jsx>{`:global(a):hover {
  :global(.movistar-m) {
    opacity: 0.8;
  }
}

:global(.movistar-m.hidden-sm) {
  display: none !important;
}
:global(.movistar-m.visible-sm) {
  display: inline-block !important;
}

@media screen and (min-width: 1024px) {
  :global(.movistar-m.hidden-sm.medium) {
    display: inline-block !important;
  }
  :global(.movistar-m.hidden-sm.big) {
    display: none !important;
  }
  :global(.movistar-m.visible-sm) {
    display: none !important;
  }
}
@media screen and (min-width: 1280px) {
  :global(.movistar-m.hidden-sm.big) {
    display: inline-block !important;
  }
  :global(.movistar-m.hidden-sm.medium) {
    display: none !important;
  }
}`}</style>
    </>
  );
};

export default Logo;
