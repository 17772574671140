import React, { Component } from "react";
import { connect } from "react-redux";
import { actions as modalActions } from "@/bit/reducers/reducers.modals-reducer";
import IconGlyph from "@/bit/components/components.icon-glyph";
import getUrlParams from '@gcloud-npm/utils.common.get-url-params'
import CustomLink from "@/bit/components/components.custom-link";
import getDeviceKindDependsOnViewPort from "@gcloud-npm/utils.common.get-device-kind-depends-on-viewport";
import SupportAndSearch from "../modals/support-search";
import SearchInput from "./search-input";

class Searcher extends Component {
  constructor() {
    super();
  }

  componentDidMount = () => {
    // Logica busqueda por parametro url
  const device = getDeviceKindDependsOnViewPort();
    const { q: queryParam } = getUrlParams({
        groupByObject: false,
        allowedParams: false,
        excludeHash: false,
        currentUrl: window.location.href,
      });
    if (queryParam && device === "MOBILE") {
      setTimeout(() => {
        this.openSearchModal()
      }, 100);
    }
  };

  openSearchModal = () => {
    const { addModal } = this.props;
    addModal({
      id: "searcher",
      Element2Show: SupportAndSearch,
      modalProps: {
        contentPosition: "center--top",
        opacityLayoutColor: "blur",
        inSceneEffect: "fadeIn",
        closeAllOnClickOutside: true,
        contentInsideContainer: true,
        outSceneEffect: "fadeOut",
        closeByClickingOutside: true,
      },
      elementProps: {
        visibleInput: true,
      },
    });
  };
  render() {
    return (
      <>
        <div className="searcher-desktop">
          <SearchInput {...this.props} className="desktop" />
        </div>
        <div className="searcher-mobile">
          <CustomLink
            onClick={this.openSearchModal}
            taggingInfo={{
              componentName: "header",
              componentContent: "openSearcher",
              componentVariation: "all",
              componentPerso: "",
              componentProduct: "",
            }}
            cyData="Header icon search"
          >
            <IconGlyph color="grey6" id="buscar-m" width={22} />
          </CustomLink>
          <style jsx>{`:global(.searcher-mobile) {
  display: block;
  :global(.svg-layout-size) {
    display: flex;
  }
}
:global(.searcher-desktop) {
  display: none;
}

:global(.icon-searcher) {
  display: inline-flex;
  padding: 0.5rem;
}

@media screen and (min-width: 768px) {
  :global(.searcher-mobile) {
    display: none;
  }
  :global(.searcher-desktop) {
    display: block;
    width: inherit;
  }

  :global(.icon-searcher) {
    display: none;
  }
}
`}</style>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addModal: (data) => dispatch(modalActions.addModal(data)),
});

export default connect(null, mapDispatchToProps)(Searcher);
